import "@ui/css/Antd.css";
import "@ui/css/Global.css";
import "@ui/css/colors/BackgroundColorClasses.css";
import "@ui/css/colors/ColorClasses.css";
import "@ui/css/colors/ColorVariables.css";
import "@ui/css/colors/ColorVariablesDarkMode.css";
import "@ui/css/colors/ColorVariablesLightMode.css";
import "@ui/css/fonts/FontClasses.css";
import "@ui/css/fonts/FontSizes.css";
import "@ui/css/fonts/FontVariables.css";
import "@ui/css/shadows/ShadowVariables.css";
import "@ui/css/z-index/ZIndexVariables.css";
import { DM_Sans } from "next/font/google";
import { AppProps } from "next/app";
import { RelayEnvironmentProvider } from "react-relay";
import { RelayEnvironment } from "@web/utils/graphql/RelayEnvironment";
import { ViewerContextProvider } from "@web/contexts/ViewerContext";

const dmSans = DM_Sans({
  subsets: ["latin"],
  // Keep in sync with FontVariableName
  weight: ["400", "500", "700"],
});

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      {/* For some reason, this works, but specifying `variable` in the font constructor does not */}
      <style jsx global>{`
        :root {
          --dm-sans: ${dmSans.style.fontFamily};
        }
      `}</style>
      <RelayEnvironmentProvider environment={RelayEnvironment}>
        <ViewerContextProvider>
          <Component {...pageProps} />
        </ViewerContextProvider>
      </RelayEnvironmentProvider>
    </>
  );
}
