var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2748990864094666b02c179a0f1c97b678e9cc4d"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/nextjs";
import { PROJECT_NAME } from "shared";
import { SENTRY_SHARED_OPTIONS } from "@web/constants/SentrySharedOptions";

Sentry.init({
  ...SENTRY_SHARED_OPTIONS,
  integrations: [new Integrations.BrowserTracing()],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});

Sentry.setTag("eventSource", "frontend-client");
Sentry.setTag("projectName", PROJECT_NAME);
